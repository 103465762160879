
	import Listitem from '../components/casestudy/listitem.vue';
  import ListitemExternal from '../components/casestudy/listitem_external.vue';
  export default {
  components: {  },
    props: ['error'],
    layout: 'errorTemplate', // you can set a custom layout for the error page
    components: {
        ListitemExternal,
        Listitem
    },
    data(){
        return {
            results: []
        }
    },
    mounted(){

        let vm = this;

        this.$axios.$post('/.netlify/functions/content',
            {
                query: "page('resources').children.limit(2)", 
                select: { 
                    "slug": "page.id", 
                    title: true,
                    resourceSummary: true,
                    "published" : 'page.published.toPrettyDate',
                    "tagTopics": 'page.tagtopic',
                    "tagTypes": 'page.tagtype',
                    intendedTemplate: true,
                    publication: true,
                    social: true,
                    external_link: true
                }
            }, {baseURL: ""}).then(function(resp){   

                vm.results = resp.data.result.data;



             }).finally(() => {  
             });
    },
    computed:  
    {
        hero: function()
        {
            let $errorText = "";
            let $HeroText = "";
        if (!this.error || this.error.statusCode === 404){
          return {
            errorText: "404: Page not found",
            HeroText: "Please update your bookmarks"
          }
        }
        else {
            return {
              errorText: "An error has occurred",
              HeroText: "Check back soon"
            }
        }
           
        
    } 
  }
  }
