import axios from 'axios'; 

export const state = () => ({
  directory: []
})

export const mutations = {
  SET_DIRECTORY(state, dir){  
    state.directory = dir
  }
}

export const actions = {
  async nuxtServerInit ({ commit, state }, { req }) {
    
    
    console.log("build directory");

    await this.$axios.$post(process.env.PRODUCTION_BASE_URL + '/api/query', {
      query: "site.index.filterBy('intendedTemplate', '!=', 'externalcard')",
      limit: 1000,
      select: {
        key: "page.id",
        author: "page.author.toUser",
        published: "page.published.toPrettyDate",
        resource_summary: "page.resourceSummary",
        resource_body: "page.resourceBody",
        parent_title: "page.parent.title",
        title: "page.title",
        head: "page.title.toSeoHelper",
        breadcrumb: "page.title.toCrumbs", 
        blocks: "page.blocks.toBlocksHelper",   
        tagTypes: "page.tagtype",
        tagTopics: "page.tagtopic",
        template: 'page.intendedTemplate', 
        listing  : "page.blocks.toListingHelper",
        podcastSpotifyLink: "page.podcastSpotifyLink",
        podcastAppleLink: "page.podcastAppleLink",
        podcastGoogleLink: "page.podcastGoogleLink",
        podcastAmazonLink: "page.podcastAmazonLink",
        podcastEmbedType: "page.podcastEmbedType",
        logo: "page.logo.toFilesHelper",
        description: "page.description",
        report: "page.report.toFilesHelper",
        embed: "page.embed",
        website: "page.website",
        showBookDemo: "page.showBookDemo",
        siblings:{
          query : "page.siblings",
          select: {
              page : "page.title",
              template: "page.intendedTemplate", 
              listing : "page.blocks.toListingHelper"
          }
        },
        subpages : {
          query : "page.children",
          select: {
            title : "page.title",
            template: "page.intendedTemplate", 
            resourceSummary: "page.resourceSummary",
            published: "page.published.toPrettyDate",
            slug: "page.id",
          }
        }    
      }
    }).then(resp =>
      {
        console.log("building...");

      //  console.log(resp.result)
        let data = resp?.result?.data;    
        commit('SET_DIRECTORY', data);    		
      }
    ).catch(function (error) {

        console.log("|ERROR!|", error);

        if (error.response) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
    });

    
  }
}

 