
import NavigationOffscreen from '../components/nav/navigationOffscreen.vue'
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import NavigationDefault from '../components/nav/navigationDefault.vue'
import NavigationFooter from '../components/nav/navigationFooter.vue'
import Futurepluslogo from '../components/images/futurepluslogo.vue'
import kirbylinkimage from '../components/kirbylinkimage.vue'
import JustValidate from 'just-validate'; 

const apiKey = process.env.GMAP_API_KEY;

	export default { 
    data(){
      return {
        scrollClass: '',
        form: {
          email_address: ''
        }
      }
    },
    name: "layoutDefault",
    mounted(){
      this.handleScroll();
      this.setupEmailValidation();

    },
    methods: { 
        closeInterrupt(){
          window.location = '/dashboard';
        },
        setupEmailValidation(){
            const validation = new JustValidate('form#interrupt', {
                errorFieldCssClass: 'is-invalid',
            });

            validation.addField('#interrupt_email', [
            {
                rule: 'required',
                errorMessage: '* email address required',
            },
            {
                rule: 'email',
                errorMessage: '* invalid email address',
            },
            {
                rule: 'minLength',
                value: 4
        }])

        let vm = this;
      
        validation.onSuccess((event) => {
                event?.preventDefault();
                
                this.$axios.post(
                    '/.netlify/functions/support',
                    this.form,
                    { 
                        baseURL: "",
                        auth: "",                                
                        headers: {
                            common: {
                            Authorization: ''
                            }
                        },
                        config: {
                            auth: ""
                        }
                    }).then(resp => {
                      window.location = 'https://knowledge.future-plus.co.uk/';
                    }).catch(err => {
                    }); 

    
            });
      
      },
        handleScroll(){
          this.scrollClass = (window.scrollY > 0 || this.hideNavState) ? 'scrollnav' : ''
        },
        ...mapMutations({
            setOffscreenState: "global/setOffscreenState",
            setHideMenuState: "global/setHideMenuState",
            setShowInterrupt: "global/setShowInterrupt",
            setShowBookDemoState: "global/setShowBookDemoState"
        }) 
    },
    created () {
        if (process.client) { 
            window.addEventListener('scroll', this.handleScroll);
        }
    },
    computed: { 
      enableDebug(){
        return process.env.NODE_ENV == 'development' ? 'debug-screens' : '';
      },
        ...mapState({
            navigationFooterA(state) {
                return state.global.content.navigationFooterA;
            },
            navigationFooterB(state) {
                return state.global.content.navigationFooterB;
            },
            navigationFooterC(state) {
                return state.global.content.navigationFooterC;
            },
            navigationFooterD(state) {
                return state.global.content.navigationFooterD;
            },
            navigationFooterAHeader(state) {
                return state.global.content.navigationFooterAHeader;
            },
            navigationFooterBHeader(state) {
                return state.global.content.navigationFooterBHeader;
            },
            navigationFooterCHeader(state) {
                return state.global.content.navigationFooterCHeader;
            },
            navigationFooterDHeader(state) {
                return state.global.content.navigationFooterDHeader;
            },
            navigationSubFooter(state) {
                return state.global.content.navigationSubFooter;
            },
            navigationMain(state) {
                return state.global.content.navigationMain;
            },
            offscreenState(state) {
                return state.global.offscreenState;
            },
            showInterrupt(state) {
                return state.global.showInterruptState;
            },
            showBookDemo(state){
              return state.global.showBookDemoState;
            },
            hideNavState(state) {
                return state.global.hideMenuState;
            },
            facebook_url(state) {
                return state.global.content.facebook_url;
            },
            linkedin_url(state) {
                return state.global.content.linkedin_url;
            },
            twitter_url(state) {
                return state.global.content.twitter_url;
            },
            instagram_url(state) {
                return state.global.content.instagram_url;
            },
            footer_address(state) {
                return state.global.content.footer_address;
            },
            map_link(state) {
                return state.global.content.map_link;
            },
            email(state) {
                return state.global.content.email;
            },
            phone(state) {
                return state.global.content.phone;
            },
            footer_logos(state){
              return state.global.content.footer_logos
            }
        })
    },
    async fetch() {
        if (process.server) {
            await this.$axios.$post("api/query", {
                query: "site",
                select: {
                    title: "site.title",
                    categories: "site.categories",
				          	footer_address: "site.footer_address", 
                    twitter_url: "site.twitter_url", 
                    instagram_url: "site.instagram_url", 
                    linkedin_url: "site.linkedin_url", 
                    navigationMain: "site.navigation_main.toNavigationArray",                    
                    navigationFooterA: "site.footer_column_a_body",
                    navigationFooterB: "site.navigation_footer_b.toNavigationArray",
                    navigationFooterC: "site.navigation_footer_c.toNavigationArray",
                    navigationFooterD: "site.navigation_footer_d.toNavigationArray",
                    navigationFooterAHeader: "site.footer_column_a_header",
                    navigationFooterBHeader: "site.footer_column_b_header",
                    navigationFooterCHeader: "site.footer_column_c_header",
                    navigationFooterDHeader: "site.footer_column_d_header", 
                    navigationSubFooter: "site.navigation_sub_footer.toNavigationArray",
                    logo_title: "site.footer_awards_title",
                    footer_logos: "site.footer_logos.toImageLinkArray"

                }
            }).then(resp => { 
                this.$store.commit("global/setGlobalContent", resp.result);
            }).catch(e => {
              
                this.$nuxt.error({
                    statusCode: 404,
                    message: "Page not found"
                  });

                console.log("navigation bar error", e);
            });
        }
    },
    components: { NavigationDefault, NavigationFooter, NavigationOffscreen, Futurepluslogo, kirbylinkimage }
}

