

	import { mapMutations } from 'vuex'
	import { mapState } from 'vuex'
	export default {
		name: 'navigationOffscreen',
		props: {
			menu: Array
		},
		data(){
			return {
				currentIndex: -1
			}
		},
		computed:{

			...mapState({
				showBookDemo(state){
					return state.global.showBookDemoState;
				}
			})
		},
		methods: {
			...mapMutations({
				setOffscreenState: 'global/setOffscreenState',
            	setShowBookDemoState: "global/setShowBookDemoState"
			}),
			open(index){
				if (this.currentIndex != index){
					this.currentIndex = index;
				}
				else{
					this.currentIndex = -1;
				}
			}
		},
	}



