

export default {
  name: "navigationDefault",
  computed: {
	  menu() {
		  return this.$store.state.global.content.navigationMain;
	  }
  },
  data(){
    return {
      openIndex: null
    }
  },
  methods: {
    updateActiveNav(val){
      this.openIndex = val;   
    },       
  },
  mounted(){
    document.querySelectorAll('.navitem').forEach(element => {
      if (element.classList.contains('nuxt-link-active-exact')){
        element.classList.add('aria-current="page"');
      }
    });
  }
};
