export const state = () => ({
	content: {},
	offscreenState: false,
	hideMenuState: false,
	showInterruptState: false,
	showBookDemoState: false
})

export const mutations = {
	setGlobalContent(state, data) { 
		state.content = data
	},
	setOffscreenState(state) {
		state.offscreenState = !state.offscreenState
	},
	setHideMenuState(state){
		state.hideMenuState = !state.hideMenuState
	},
	setShowBookDemoState(state){
		state.showBookDemoState = !state.showBookDemoState
	},
	setShowInterrupt(state){
		state.showInterruptState = !state.showInterruptState
	}
}
