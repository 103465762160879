
    import NavigationOffscreen from '../components/nav/navigationOffscreen.vue'
    import { mapState } from 'vuex'
    import { mapMutations } from 'vuex'
    import NavigationDefault from '../components/nav/navigationDefault.vue'
    import NavigationFooter from '../components/nav/navigationFooter.vue'
    import Futurepluslogo from '../components/images/futurepluslogo.vue'
    import kirbylinkimage from '../components/kirbylinkimage.vue'
    
    const apiKey = process.env.GMAP_API_KEY;
    
        export default { 
        data(){
          return {
            scrollClass: ''
          }
        },
        name: "errorTemplate",
        mounted(){
          this.handleScroll();
        },
        methods: { 
            handleScroll(){
              this.scrollClass = window.scrollY > 0 ? 'scrollnav' : ''
            },
            ...mapMutations({
                setOffscreenState: "global/setOffscreenState"
            })
        },
        created () {
            if (process.client) { 
                window.addEventListener('scroll', this.handleScroll);
            }
        },
        computed: { 
          enableDebug(){
            return process.env.NODE_ENV == 'development' ? 'debug-screens' : '';
          },
            ...mapState({
                navigationFooterA(state) {
                    return state.global.content.navigationFooterA;
                },
                navigationFooterB(state) {
                    return state.global.content.navigationFooterB;
                },
                navigationFooterC(state) {
                    return state.global.content.navigationFooterC;
                },
                navigationFooterD(state) {
                    return state.global.content.navigationFooterD;
                },
                navigationFooterAHeader(state) {
                    return state.global.content.navigationFooterAHeader;
                },
                navigationFooterBHeader(state) {
                    return state.global.content.navigationFooterBHeader;
                },
                navigationFooterCHeader(state) {
                    return state.global.content.navigationFooterCHeader;
                },
                navigationFooterDHeader(state) {
                    return state.global.content.navigationFooterDHeader;
                },
                navigationSubFooter(state) {
                    return state.global.content.navigationSubFooter;
                },
                navigationMain(state) {
                    return state.global.content.navigationMain;
                },
                offscreenState(state) {
                    return state.global.offscreenState;
                },
                facebook_url(state) {
                    return state.global.content.facebook_url;
                },
                linkedin_url(state) {
                    return state.global.content.linkedin_url;
                },
                twitter_url(state) {
                    return state.global.content.twitter_url;
                },
                instagram_url(state) {
                    return state.global.content.instagram_url;
                },
                footer_address(state) {
                    return state.global.content.footer_address;
                },
                map_link(state) {
                    return state.global.content.map_link;
                },
                email(state) {
                    return state.global.content.email;
                },
                phone(state) {
                    return state.global.content.phone;
                },
                footer_logos(state){
                  return state.global.content.footer_logos
                }
            })
        },
        async fetch() { 
                await this.$axios.$post("/.netlify/functions/content", {
                    query: "site",
                    select: {
                        title: "site.title",
                        categories: "site.categories",
                                  footer_address: "site.footer_address", 
                        twitter_url: "site.twitter_url", 
                        instagram_url: "site.instagram_url", 
                        linkedin_url: "site.linkedin_url", 
                        navigationMain: "site.navigation_main.toNavigationArray",                    
                        navigationFooterA: "site.footer_column_a_body",
                        navigationFooterB: "site.navigation_footer_b.toNavigationArray",
                        navigationFooterC: "site.navigation_footer_c.toNavigationArray",
                        navigationFooterD: "site.navigation_footer_d.toNavigationArray",
                        navigationFooterAHeader: "site.footer_column_a_header",
                        navigationFooterBHeader: "site.footer_column_b_header",
                        navigationFooterCHeader: "site.footer_column_c_header",
                        navigationFooterDHeader: "site.footer_column_d_header", 
                        navigationSubFooter: "site.navigation_sub_footer.toNavigationArray",
                        logo_title: "site.footer_awards_title",
                        footer_logos: "site.footer_logos.toImageLinkArray"
    
                    }
                },{ 
                    baseURL: "",
                    auth: "",                                
                    headers: {
                        common: {
                        Authorization: ''
                        }
                    },
                    config: {
                        auth: ""
                    }}).then(resp => { 
                        console.log(resp.data.result);
                    this.$store.commit("global/setGlobalContent", resp.data.result);
                }).catch(e => {
                   
                    console.log("navigation bar error", e);
                }); 
        },
        components: { NavigationDefault, NavigationFooter, NavigationOffscreen, Futurepluslogo, kirbylinkimage }
    }
    
    